import React from 'react'
import style from "./style.module.scss";


const Contacts = () => {
  return (
    <main className={style.section}>
        <div className={style.container}>
        <h2 className={style.title1}>Contacts</h2>
        <p className={style.name_title}>ANASTASIA PALUNINA</p>
        <p>Based in Milan</p>
        <p>E-mail: <a href="mailto:palunina.style@gmail.com">palunina.style@gmail.com</a></p>
        <p>Mobile: <a href="tel:+393505295273">+39 3505295273</a></p>
        <p>Instagram: <a href="https://instagram.com/anastasia_palunina" target="_blank" rel="noopener noreferrer">@anastasia_palunina</a></p>
        
<hr/>

                

        </div>
    </main>
  )
}

export default Contacts;