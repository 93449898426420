import photo_1 from "./../img/projects/photo_1.jpg";
import photo_2 from "./../img/projects/photo_2.jpg";
import photo_3 from "./../img/projects/photo_3.jpg";
import photo_4 from "./../img/projects/photo_4.jpg";
import photo_5 from "./../img/projects/photo_5.jpg";
import photo_6 from "./../img/projects/photo_6.jpg";
import photo_7 from "./../img/projects/photo_7.jpg";
import photo_8 from "./../img/projects/photo_8.jpg";
import photo_9 from "./../img/projects/photo_9.jpg";
import photo_10 from "./../img/projects/photo_10.jpg";
import photo_11 from "./../img/projects/photo_11.jpg";
import photo_12 from "./../img/projects/photo_12.jpg";
import photo_13 from "./../img/projects/photo_13.jpg";
import photo_14 from "./../img/projects/photo_14.jpg";
import photo_15 from "./../img/projects/photo_15.jpg";
import photo_16 from "./../img/projects/photo_16.jpeg";
import photo_17 from "./../img/projects/photo_17.jpg";
import photo_18 from "./../img/projects/photo_18.jpg";
import photo_19 from "./../img/projects/photo_19.jpg";
import photo_20 from "./../img/projects/photo_20.jpg";
import photo_21 from "./../img/projects/photo_21.jpg";
import photo_22 from "./../img/projects/photo_22.jpg";
import photo_23 from "./../img/projects/photo_23.jpg";
import photo_24 from "./../img/projects/photo_24.jpg";
import photo_25 from "./../img/projects/photo_25.jpg";
import photo_26 from "./../img/projects/photo_26.jpg";
import photo_27 from "./../img/projects/photo_27.jpg";
import photo_28 from "./../img/projects/photo_28.jpg";
import photo_29 from "./../img/projects/photo_29.jpg";
import photo_30 from "./../img/projects/photo_30.jpg";
import photo_31 from "./../img/projects/photo_31.jpg";
import photo_32 from "./../img/projects/photo_32.jpg";
import photo_33 from "./../img/projects/photo_33.jpg";
import photo_34 from "./../img/projects/photo_34.jpg";
import photo_35 from "./../img/projects/photo_35.jpg";
import photo_36 from "./../img/projects/photo_36.jpg";

const projects = [
  { img: photo_1, alt: "project1 photo" },
  { img: photo_2, alt: "project2 photo" },
  { img: photo_3, alt: "project3 photo" },
  { img: photo_4, alt: "project photo" },
  { img: photo_5, alt: "project photo" },
  { img: photo_6, alt: "project photo" },
  { img: photo_7, alt: "project photo" },
  { img: photo_8, alt: "project photo" },
  { img: photo_9, alt: "project photo" },
  { img: photo_10, alt: "project photo" },
  { img: photo_11, alt: "project photo" },
  { img: photo_12, alt: "project photo" },
  { img: photo_13, alt: "project photo" },
  { img: photo_14, alt: "project photo" },
  { img: photo_15, alt: "project photo" },
  { img: photo_16, alt: "project photo" },
  { img: photo_17, alt: "project photo" },
  { img: photo_18, alt: "project photo" },
  { img: photo_19, alt: "project photo" },
  { img: photo_20, alt: "project photo" },
  { img: photo_21, alt: "project photo" },
  { img: photo_22, alt: "project photo" },
  { img: photo_23, alt: "project photo" },
  { img: photo_24, alt: "project photo" },
  { img: photo_25, alt: "project photo" },
  { img: photo_26, alt: "project photo" },
  { img: photo_27, alt: "project photo" },
  { img: photo_28, alt: "project photo" },
  { img: photo_29, alt: "project photo" },
  { img: photo_30, alt: "project photo" },
  { img: photo_31, alt: "project photo" },
  { img: photo_32, alt: "project photo" },
  { img: photo_33, alt: "project photo" },
  { img: photo_34, alt: "project photo" },
  { img: photo_35, alt: "project photo" },
  { img: photo_36, alt: "project photo" },
];

export { projects };
