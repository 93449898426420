import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Navigation from "./Components/Navigation/Navigation";
import Footer from "./Components/Footer/Footer";
import Projects from "./pages/Projects/Projects";
import Contacts from "./pages/Contacts/Contacts";
import About from "./pages/About/About";
import Services from "./pages/Services/Services";
import ScrollToTop from "./utils/scrollToTop";
import NotFound from "./Components/NotFound/NotFound";

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Navigation />
        <Routes>
          <Route path="/" exact element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
