import React from 'react';
import style from "./style.module.scss";
import AnastasiiaPhoto from "../../img/Anastasiia_photo.jpg";

const About = () => {
  return (
    <div className={style.about}>
    <div className={style.container}>
      <div className={style.img_div}>
      <img src={AnastasiiaPhoto} alt="Anastasiia Palunina" />
      </div>
      <div className={style.about_text}>
        <p>Anastasia Palunina is a Russian fashion stylist and consultant based in Milan. With a background in fashion design and tailoring art from Moscow she began her career as a stylist in Italy, 2022.

</p>
        <p>During her experience, she has implemented numerous projects, including advertising campaigns, editorials for L'Officiel and Harper's Bazaar, and assisted celebrities, as well as Robbie Spencer and Davide Brambilla, at fashion shows during MFW.

</p>
        <p>With a keen eye for detail and a passion for creativity she strives to bring her projects to life in a authentic way. Her works embody elegance with a modern and bold interpretation.

 </p>
      </div>
    </div>
    
  </div>
  )
}

export default About;