import React from 'react'
import style from "./style.module.scss";
import instagram from "./../../img/icons/instagram.svg";
import linkedin from "./../../img/icons/linkedIn.svg";
import whatsapp from "./../../img/icons/whatsapp.svg";


 const Footer = () => {
  return (
    <footer className={style.footer}>
    <div className={style.container}>
    <div className={style.footer__wrapper}>
    <ul className={style.social}>
        <li className={style.social__item}><a href="https://www.instagram.com/anastasia_palunina/" target="_blank"  rel="noopener noreferrer"><img src={instagram} alt="Instagram link"/></a></li>
        <li className={style.social__item}><a href="https://linkedin.com/in/anastasiia-palunina" target="_blank"  rel="noopener noreferrer"><img src={linkedin} alt="Linkedin link"/></a></li>
        <li className={style.social__item}><a href="https://wa.me/79167377830" target="_blank"  rel="noopener noreferrer"><img src={whatsapp} alt="WhatsApp link"/></a></li>
        </ul> 

        <div className={style.copyright}>
                    <p>© 2024 Anastasia Palunina</p>
                </div>         
    </div>
    </div>
</footer>
  )
}
export default Footer;