import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';
import {AiOutlineMenu, AiOutlineClose} from "react-icons/ai";
import style from "./style.module.scss"

const Navigation = ()=>{
const [nav, setNav] = useState(false);

const activeLink= style.activeLink;
const normalLink=style.normalLink;
const closeMenu = () => {
  setNav(false);
};

  return (
    <nav className={style.nav}>
    <div className={style.container}>
    <div className={style.navrow}>
          <NavLink to="/" className={style.logo}>ANASTASIA PALUNINA</NavLink>
        

<ul className={nav ? [style.navlist, style.mobile_active].join(" ") : style.navlist}>
            
            <li className={style.navlist__item}>
              <NavLink to="/about"  className={({isActive})=> isActive ? activeLink : normalLink} onClick={closeMenu}>ABOUT
              </NavLink></li>
                <li className={style.navlist__item}><NavLink to="/" className={({isActive})=> isActive ? activeLink : normalLink} onClick={closeMenu}>PORTFOLIO</NavLink> </li>
                <li className={style.navlist__item}><NavLink to="/services" className={({isActive})=> isActive ? activeLink : normalLink} onClick={closeMenu}>WORKS</NavLink></li>
                <li className={style.navlist__item}><NavLink to="/contacts" className={({isActive})=> isActive ? activeLink : normalLink}onClick={closeMenu}>CONTACTS</NavLink></li>
            </ul>
            </div>
            <div onClick={()=>setNav(!nav)} className={style.mobile_btn}>
            {nav ? <AiOutlineClose className={style.closeIcon_Active}/> : <AiOutlineMenu/>}
            </div>
    </div>
    </nav>
  )
}

export default Navigation;