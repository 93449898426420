import React from 'react'
import Gallery from '../../Components/Gallery/Gallery';
import BackToTopButton from '../../Components/BackToTopButton/BackToTopButton';
import {galleriesData} from "../../helpers/galleries"; 
import style from "./style.module.scss";

const Services = () => {
  return (
    <main className={style.section}>
        <div className={style.container}>
                      
        {galleriesData.map((gallery) => (
          <Gallery 
          key={gallery.id} 
          images={gallery.images}  
          description={gallery.description}
          />
        ))}
        </div>
   <BackToTopButton/>
    </main>
  )
}

export default Services;