// import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay,  EffectFade } from 'swiper/modules';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/effect-fade';
import style from "./style.module.scss";


const Gallery = ({ images, description }) => {
  return (
    <div className={style.slider_wrappper}>
       <h1 className={style.slide_description }>{description}</h1>
    <Swiper
    modules={[Navigation, Pagination, Autoplay, Scrollbar, EffectFade]}
    slidesPerView={1}
    slidesPerGroup={1}
    effect='fade'
    navigation
    loop={true}
        pagination={{
        type: 'fraction',
      }}
      scrollbar={{ draggable: true }}
            speed={1000}
    onSlideChange={() => console.log('slide change')}
    onSwiper={(swiper) => console.log(swiper)}
  >
        {images.map((image, index) => (
          <SwiperSlide key={index} className={style.slide_img}>
            <img src={image.src} alt={image.alt} loading="lazy"/>
          </SwiperSlide>
        ))}
 
   </Swiper>
 
  </div>
  );
};

export default Gallery;
  