import React from 'react';
import { NavLink } from 'react-router-dom';
import style from "./style.module.scss";


const NotFound = () => {
  return (
    <div className={style.notFoundContainer}>
    <h1>404 - Page Not Found</h1>
    <p>Sorry, the page you are looking for does not exist.</p>
    <NavLink to="/" className={style.backLink}>
      Go back to the homepage
    </NavLink>
  </div>
  )
}

export default NotFound;