import React from 'react';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import Project from '../../Components/Project/Project';
import BackToTopButton from '../../Components/BackToTopButton/BackToTopButton';
import {projects} from "./../../helpers/projectsList";
import style from "./style.module.scss";


const Projects = () => {
  return (
    <main className={style.section}>        
   
        <div className={style.container}>                

              <ul className={style.projects}> 
          <ResponsiveMasonry
                columnsCountBreakPoints={{350: 2, 750: 2, 900: 4}}
            >
                <Masonry gutter="10px">
              {projects.map((project, index)=>{
                return <Project key={index} 
                img={project.img}
                alt={project.alt}
                index={index}
                loading="lazy" />
              })}
                     </Masonry>
            </ResponsiveMasonry>
            </ul>
   <BackToTopButton/>
        </div>  
    </main>
  )
}

export default Projects;