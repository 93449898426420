import React, {useState} from 'react';
import style from "./style.module.scss";


const Project = ({img, alt, loading}) => {
  
  const [isIncreased, setIsIncreased] = useState(false);
 

  const handleImageClick =()=>{
       setIsIncreased(true);
       
      }

   const handleOverlayClick = () =>{
      setIsIncreased(false);
           }
  let imgClass = style.projectImg;
  if(isIncreased){
    imgClass +=` ${style.increased}`;
    }


  return (
    <div>
            <ul>
         <li className={style.project}>
                  <img 
                  src={img} 
                  alt={alt} 
                  className={`${style.projectImg} ${isIncreased ? style.increased : ''}`}
                  onClick={handleImageClick}
                  loading={loading}/>
          </li></ul>
          {isIncreased && (  <div className={style.overlay}  onClick={handleOverlayClick}> 
          <img 
            src={img} 
            alt={alt} 
            className={style.increasedImg} 
            loading={loading}
                      />
        </div>)

          }
 </div>
  )
}

export default Project;