import harpersBazaar_1 from "./../img/works/Harper's Bazaar/harpersBazaar_1.jpg";
import harpersBazaar_2 from "./../img/works/Harper's Bazaar/harpersBazaar_2.jpg";
import harpersBazaar_3 from "./../img/works/Harper's Bazaar/harpersBazaar_3.jpg";
import harpersBazaar_4 from "./../img/works/Harper's Bazaar/harpersBazaar_4.jpg";
import harpersBazaar_5 from "./../img/works/Harper's Bazaar/harpersBazaar_5.jpg";
import harpersBazaar_6 from "./../img/works/Harper's Bazaar/harpersBazaar_6.jpg";
import harpersBazaar_7 from "./../img/works/Harper's Bazaar/harpersBazaar_7.jpg";
import harpersBazaar_8 from "./../img/works/Harper's Bazaar/harpersBazaar_8.jpg";

import keria_1 from "./../img/works/Model test/Keria/keria_1.jpg";
import keria_2 from "./../img/works/Model test/Keria/keria_2.jpg";
import keria_3 from "./../img/works/Model test/Keria/keria_3.jpg";

import sandra_1 from "./../img/works/Model test/Sandra/sandra_1.jpg";
import sandra_2 from "./../img/works/Model test/Sandra/sandra_2.jpg";
import sandra_3 from "./../img/works/Model test/Sandra/sandra_3.jpg";
import sandra_4 from "./../img/works/Model test/Sandra/sandra_4.jpg";
import sandra_5 from "./../img/works/Model test/Sandra/sandra_5.jpg";

import azalea1 from "./../img/works/Fashion Campaign/AZA LEA/aza_lea_1.jpg";
import azalea2 from "./../img/works/Fashion Campaign//AZA LEA/aza_lea_2.jpg";
import azalea3 from "./../img/works/Fashion Campaign//AZA LEA/aza_lea_3.jpg";
import azalea4 from "./../img/works/Fashion Campaign//AZA LEA/aza_lea_4.jpg";
import azalea5 from "./../img/works/Fashion Campaign//AZA LEA/aza_lea_5.jpg";
import azalea6 from "./../img/works/Fashion Campaign//AZA LEA/aza_lea_6.jpg";

import lofficiel_baltic_1 from "./../img/works/Editorials/L`Officiel Baltic/lofficiel_baltic_1.jpg";
import lofficiel_baltic_2 from "./../img/works/Editorials/L`Officiel Baltic/lofficiel_baltic_2.jpg";
import lofficiel_baltic_3 from "./../img/works/Editorials/L`Officiel Baltic/lofficiel_baltic_3.jpg";
import lofficiel_baltic_4 from "./../img/works/Editorials/L`Officiel Baltic/lofficiel_baltic_4.jpg";
import lofficiel_baltic_5 from "./../img/works/Editorials/L`Officiel Baltic/lofficiel_baltic_5.jpg";
import lofficiel_baltic_6 from "./../img/works/Editorials/L`Officiel Baltic/lofficiel_baltic_6.jpg";
import lofficiel_baltic_7 from "./../img/works/Editorials/L`Officiel Baltic/lofficiel_baltic_7.jpg";

import rebel_1 from "./../img/works/Editorials/Rebel/rebel_1.jpg";
import rebel_2 from "./../img/works/Editorials/Rebel/rebel_2.jpg";
import rebel_3 from "./../img/works/Editorials/Rebel/rebel_3.jpg";
import rebel_4 from "./../img/works/Editorials/Rebel/rebel_4.jpg";
import rebel_5 from "./../img/works/Editorials/Rebel/rebel_5.jpg";
import rebel_6 from "./../img/works/Editorials/Rebel/rebel_6.jpg";
import rebel_7 from "./../img/works/Editorials/Rebel/rebel_7.jpg";
import rebel_8 from "./../img/works/Editorials/Rebel/rebel_8.jpg";

import lofficiel_winter_garden_1 from "./../img/works/Editorials/L`Officiel WInter Garden/lofficiel_winter_garden_1.jpg";
import lofficiel_winter_garden_2 from "./../img/works/Editorials/L`Officiel WInter Garden/lofficiel_winter_garden_2.jpg";
import lofficiel_winter_garden_3 from "./../img/works/Editorials/L`Officiel WInter Garden/lofficiel_winter_garden_3.jpg";
import lofficiel_winter_garden_4 from "./../img/works/Editorials/L`Officiel WInter Garden/lofficiel_winter_garden_4.jpg";
import lofficiel_winter_garden_5 from "./../img/works/Editorials/L`Officiel WInter Garden/lofficiel_winter_garden_5.jpg";
import lofficiel_winter_garden_6 from "./../img/works/Editorials/L`Officiel WInter Garden/lofficiel_winter_garden_6.jpg";
import lofficiel_winter_garden_7 from "./../img/works/Editorials/L`Officiel WInter Garden/lofficiel_winter_garden_7.jpg";
import lofficiel_winter_garden_8 from "./../img/works/Editorials/L`Officiel WInter Garden/lofficiel_winter_garden_8.jpg";

import viktoriia_1 from "./../img/works/Model test/Viktoriia/Viktoria_ 1.jpg";
import viktoriia_2 from "./../img/works/Model test/Viktoriia/Viktoria_ 2.jpg";
import viktoriia_3 from "./../img/works/Model test/Viktoriia/Viktoria_ 3.jpg";
import viktoriia_4 from "./../img/works/Model test/Viktoriia/Viktoria_ 4.jpg";
import viktoriia_5 from "./../img/works/Model test/Viktoriia/Viktoria_ 5.jpg";

import aline_1 from "./../img/works/Model test/Aline/aline_1.jpg";
import aline_2 from "./../img/works/Model test/Aline/aline_2.jpg";
import aline_3 from "./../img/works/Model test/Aline/aline_3.jpg";

import ADSB_1 from "./../img/works/Fashion week assistance/ADSB/ADSB_1.jpg";
import ADSB_2 from "./../img/works/Fashion week assistance/ADSB/ADSB_2.jpg";
import ADSB_3 from "./../img/works/Fashion week assistance/ADSB/ADSB_3.jpg";
import ADSB_4 from "./../img/works/Fashion week assistance/ADSB/ADSB_4.jpg";
import ADSB_5 from "./../img/works/Fashion week assistance/ADSB/ADSB_5.jpg";
import ADSB_6 from "./../img/works/Fashion week assistance/ADSB/ADSB_6.jpg";
import ADSB_7 from "./../img/works/Fashion week assistance/ADSB/ADSB_7.jpg";
import ADSB_8 from "./../img/works/Fashion week assistance/ADSB/ADSB_8.jpg";
import ADSB_9 from "./../img/works/Fashion week assistance/ADSB/ADSB_9.jpg";
import ADSB_10 from "./../img/works/Fashion week assistance/ADSB/ADSB_10.jpg";
import ADSB_11 from "./../img/works/Fashion week assistance/ADSB/ADSB_11.jpg";

import yana_yass_1 from "./../img/works/Fashion week assistance/Yana Yass/yana_yass_1.jpg";
import yana_yass_2 from "./../img/works/Fashion week assistance/Yana Yass/yana_yass_2.jpg";
import yana_yass_3 from "./../img/works/Fashion week assistance/Yana Yass/yana_yass_3.jpg";
import yana_yass_4 from "./../img/works/Fashion week assistance/Yana Yass/yana_yass_4.jpg";
import yana_yass_5 from "./../img/works/Fashion week assistance/Yana Yass/yana_yass_5.jpg";

import puss_1 from "./../img/works/Editorials/PUSS PUSS/puss_1.jpg";
import puss_2 from "./../img/works/Editorials/PUSS PUSS/puss_2.jpg";
import puss_3 from "./../img/works/Editorials/PUSS PUSS/puss_3.jpg";
import puss_4 from "./../img/works/Editorials/PUSS PUSS/puss_4.jpg";
import puss_5 from "./../img/works/Editorials/PUSS PUSS/puss_5.jpg";
import puss_6 from "./../img/works/Editorials/PUSS PUSS/puss_6.jpg";

const galleriesData = [
  {
    id: 1,
    title: "Gallery 1",
    images: [
      { src: keria_1, alt: "Keria model test photo" },
      { src: keria_2, alt: "Keria model test photo" },
      { src: keria_3, alt: "Keria model test photo" },
    ],
    description: "Model test Keria // May, 2024",
  },
  {
    id: 2,
    title: "Gallery 2",
    images: [
      {
        src: lofficiel_winter_garden_1,
        alt: "L`Officiel Baltic Winter Garden photo",
      },
      {
        src: lofficiel_winter_garden_2,
        alt: "L`Officiel Baltic Winter Garden photo",
      },
      {
        src: lofficiel_winter_garden_3,
        alt: "L`Officiel Baltic Winter Garden photo",
      },
      {
        src: lofficiel_winter_garden_4,
        alt: "L`Officiel Baltic Winter Garden photo",
      },
      {
        src: lofficiel_winter_garden_5,
        alt: "L`Officiel Baltic Winter Garden photo",
      },
      {
        src: lofficiel_winter_garden_6,
        alt: "L`Officiel Baltic Winter Garden photo",
      },
      {
        src: lofficiel_winter_garden_7,
        alt: "L`Officiel Baltic Winter Garden photo",
      },
      {
        src: lofficiel_winter_garden_8,
        alt: "L`Officiel Baltic Winter Garden photo",
      },
    ],
    description: 'L`Officiel Baltic "Winter Garden" // January, 2024',
  },

  {
    id: 3,
    title: "Gallery 3",
    images: [
      { src: sandra_1, alt: "Sandra model test photo" },
      { src: sandra_2, alt: "Sandra model test photo" },
      { src: sandra_3, alt: "Sandra model test photo" },
      { src: sandra_4, alt: "Sandra model test photo" },
      { src: sandra_5, alt: "Sandra model test photo" },
    ],
    description: "Model test Sandra // May, 2024",
  },
  {
    id: 4,
    title: "Gallery 4",
    images: [
      { src: harpersBazaar_1, alt: "Harpers Bazaar photo" },
      { src: harpersBazaar_2, alt: "Harpers Bazaar photo" },
      { src: harpersBazaar_3, alt: "Harpers Bazaar photo" },
      { src: harpersBazaar_4, alt: "Harpers Bazaar photo" },
      { src: harpersBazaar_5, alt: "Harpers Bazaar photo" },
      { src: harpersBazaar_6, alt: "Harpers Bazaar photo" },
      { src: harpersBazaar_7, alt: "Harpers Bazaar photo" },
      { src: harpersBazaar_8, alt: "Harpers Bazaar photo" },
    ],
    description: "Harper`s Bazaar // March, 2024",
  },
  {
    id: 5,
    title: "Gallery 5",
    images: [
      { src: puss_1, alt: "PUSS PUSS Issue 20 photo" },
      { src: puss_2, alt: "PUSS PUSS Issue 20 photo" },
      { src: puss_3, alt: "PUSS PUSS Issue 20 photo" },
      { src: puss_4, alt: "PUSS PUSS Issue 20 photo" },
      { src: puss_5, alt: "PUSS PUSS Issue 20 photo" },
      { src: puss_6, alt: "PUSS PUSS Issue 20 photo" },
    ],
    description:
      "PUSS PUSS Issue 20 / Styling assistance to Andrea Colace // July, 2024",
  },
  {
    id: 6,
    title: "Gallery 6",
    images: [
      { src: azalea1, alt: "AZA LEA photo" },
      { src: azalea2, alt: "AZA LEA photo" },
      { src: azalea3, alt: "AZA LEA photo" },
      { src: azalea4, alt: "AZA LEA photo" },
      { src: azalea5, alt: "AZA LEA photo" },
      { src: azalea6, alt: "AZA LEA photo" },
    ],
    description: "Campaign AZA LEA // SS’24",
  },
  {
    id: 7,
    title: "Gallery 7",
    images: [
      { src: lofficiel_baltic_1, alt: "L`Officiel Baltic photo" },
      { src: lofficiel_baltic_2, alt: "L`Officiel Baltic photo" },
      { src: lofficiel_baltic_3, alt: "L`Officiel Baltic photo" },
      { src: lofficiel_baltic_4, alt: "L`Officiel Baltic photo" },
      { src: lofficiel_baltic_5, alt: "L`Officiel Baltic photo" },
      { src: lofficiel_baltic_6, alt: "L`Officiel Baltic photo" },
      { src: lofficiel_baltic_7, alt: "L`Officiel Baltic photo" },
    ],
    description: 'L`Officiel Baltic "Arco della Pace" // May, 2024',
  },
  {
    id: 8,
    title: "Gallery 8",
    images: [
      { src: rebel_1, alt: "Rebel photo" },
      { src: rebel_2, alt: "Rebel photo" },
      { src: rebel_3, alt: "Rebel photo" },
      { src: rebel_4, alt: "Rebel photo" },
      { src: rebel_5, alt: "Rebel photo" },
      { src: rebel_6, alt: "Rebel photo" },
      { src: rebel_7, alt: "Rebel photo" },
      { src: rebel_8, alt: "Rebel photo" },
    ],
    description: 'Rebel "Porta Venezia" // May, 2024',
  },

  {
    id: 9,
    title: "Gallery 9",
    images: [
      { src: viktoriia_1, alt: "Viktoriia model test photo" },
      { src: viktoriia_2, alt: "Viktoriia model test photo" },
      { src: viktoriia_3, alt: "Viktoriia model test photo" },
      { src: viktoriia_4, alt: "Viktoriia model test photo" },
      { src: viktoriia_5, alt: "Viktoriia model test photo" },
    ],
    description: "Model test Viktoriia // March, 2024",
  },
  {
    id: 10,
    title: "Gallery 10",
    images: [
      { src: aline_1, alt: "Rebel Roman story photo" },
      { src: aline_2, alt: "Rebel Roman story photo" },
      { src: aline_3, alt: "Rebel Roman story photo" },
    ],
    description: "Model portfolio Aline // October, 2024",
  },
  {
    id: 11,
    title: "Gallery 11",
    images: [
      { src: ADSB_1, alt: "ADSB Andersson Bell Show photo" },
      { src: ADSB_2, alt: "ADSB Andersson Bell Show photo" },
      { src: ADSB_3, alt: "ADSB Andersson Bell Show photo" },
      { src: ADSB_4, alt: "ADSB Andersson Bell Show photo" },
      { src: ADSB_5, alt: "ADSB Andersson Bell Show photo" },
      { src: ADSB_6, alt: "ADSB Andersson Bell Show photo" },
      { src: ADSB_7, alt: "ADSB Andersson Bell Show photo" },
      { src: ADSB_8, alt: "ADSB Andersson Bell Show photo" },
      { src: ADSB_9, alt: "ADSB Andersson Bell Show photo" },
      { src: ADSB_10, alt: "ADSB Andersson Bell Show photo" },
      { src: ADSB_11, alt: "ADSB Andersson Bell Show photo" },
    ],
    description:
      "ADSB Fall Winter 2024 / Milano Fashion Week // styling assistance to Robbie Spencer",
  },
  {
    id: 12,
    title: "Gallery 12",
    images: [
      { src: yana_yass_1, alt: "Assistance to Yana Yass photo" },
      { src: yana_yass_2, alt: "Assistance to Yana Yass photo" },
      { src: yana_yass_3, alt: "Assistance to Yana Yass photo" },
      { src: yana_yass_4, alt: "Assistance to Yana Yass photo" },
      { src: yana_yass_5, alt: "Assistance to Yana Yass photo" },
    ],
    description:
      "Yana Yass / Milano Fashion Week Spring Summer 2024 // Styling assistance",
  },
];

export { galleriesData };
