import React, { useEffect, useState } from 'react';
import scollUp_button from "./../../img/icons/scrollUp_button.svg";
import style from "./style.module.scss";



const BackToTopButton = () => {
    const [backToTopButton, setBackToTopButton] = useState(false);
    useEffect (()=>{
        window.addEventListener("scroll", ()=>{
            if(window.scrollY > 250){
                setBackToTopButton(true)
            } else {
                setBackToTopButton (false)
            }
        })
    },[]);

const scrollUp = ()=>{
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })
}
  return (
    <div>
        {backToTopButton &&(
            <button className={style.backToTopButton}
            onClick={scrollUp}><img src={scollUp_button} className={style.up_img} alt="scroll up button"/></button>
        )
        }
    </div>
  )
}

export default BackToTopButton;